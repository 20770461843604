.spinner {
  animation: spinner 0.5s infinite linear;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
    margin-top: 28px;
    margin-bottom: -28px;
  }
  15% {
    transform: rotate(25deg);
    margin-top: 5px;
    margin-bottom: -5px;
  }
  40% {
    transform: rotate(-10deg);
    margin-top: 7px;
    margin-bottom: -7px;
  }
  65% {
    transform: rotate(5deg);
    margin-top: 12px;
    margin-bottom: -12px;
  }
  90% {
    transform: rotate(-15deg);
    margin-top: 0;
    margin-bottom: 0;
  }
}
