.note-pad {
  background-image: -webkit-linear-gradient(left, #f7fafc 0px, transparent 0px),
    -webkit-linear-gradient(right, #f7fafc 0px, transparent 0px),
    -webkit-linear-gradient(#f7fafc 24px, #e7e7e7 24px, #e7e7e7 25px, #f7fafc
          25px);
  padding-top: 2px;
  background-size: 100% 100%, 100% 100%, 100% 25px;
  line-height: 25px;
}
.note-pad:focus {
  background-image: -webkit-linear-gradient(left, #fffaf0 0px, transparent 0px),
    -webkit-linear-gradient(right, #fffaf0 0px, transparent 0px),
    -webkit-linear-gradient(#fffaf0 24px, #e7e7e7 24px, #e7e7e7 25px, #fffaf0
          25px);
}
.note-pad-big {
  background-image: -webkit-linear-gradient(left, #f7fafc 0px, transparent 0px),
    -webkit-linear-gradient(right, #f7fafc 0px, transparent 0px),
    -webkit-linear-gradient(#f7fafc 30px, #e7e7e7 30px, #e7e7e7 31px, #f7fafc
          31px);
  padding-top: 2px;
  background-size: 100% 100%, 100% 100%, 100% 31px;
  line-height: 31px;
}
.note-pad-big:focus {
  background-image: -webkit-linear-gradient(left, #fffaf0 0px, transparent 0px),
    -webkit-linear-gradient(right, #fffaf0 0px, transparent 0px),
    -webkit-linear-gradient(#fffaf0 30px, #e7e7e7 30px, #e7e7e7 31px, #fffaf0
          31px);
}

.p-full {
  padding: 100%;
}
.pt-full {
  padding-top: 100%;
}
.pb-full {
  padding-bottom: 100%;
}
.pl-full {
  padding-left: 100%;
}
.pr-full {
  padding-right: 100%;
}
.px-full {
  padding-left: 100%;
  padding-right: 100%;
}
.py-full {
  padding-top: 100%;
  padding-bottom: 100%;
}
