div.header {
  width: 100%;
  border-bottom: 2px solid black;
  display: inline-block;
  min-height: 65px;
  max-height: 100px;
  z-index: 200;
  height: 100px;
}
div.sub-header {
  padding-top: 114px;
}
div.header:after {
  display: none;
}
div.header:before {
  display: table;
  content: " ";
}
#headerContainer {
  flex-wrap: nowrap;
}
#headerContainer:before {
  display: none;
}
#headerContinaer:after {
  display: table;
  content: " ";
}
div.header img {
  max-width: 150px;
  width: 100%;
}
